<template>
  <v-card class="pa-6 rounded-lg" outlined>
    <div class="d-flex align-center mb-2">
      <div class="text-h6 secondary--text">
        <span > Últimas Transações </span>
      </div>

      <v-spacer />

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="text-none" plain small>
            {{ filters.find((e) => e.value === filter).label }}
            <v-icon class="mr-2" small>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, i) in filters"
            :key="i"
            @click="changeFilter(item.value)"
          >
            <v-list-item-title>
              <span >
                {{ item.label }}
              </span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-divider class="my-4" />

    <v-data-table
      class="secondary--text transparent custom-table"
      :headers="headers"
      :items="transactions"
      hide-default-footer
      disable-sort
    >
      <template v-slot:[`item.contract`]="{ item }">
        <span >
          {{ item.nomeContrato }}
        </span>
      </template>

      <template v-slot:[`item.date`]="{ item }">
        <span >
          {{ formatDate(item.data) }}
        </span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip class="rounded-lg" outlined>
          <span >
            {{ handleStatus(item.status) }}
          </span>
        </v-chip>
      </template>

      <template v-slot:[`item.value`]="{ item }">
        <span class="secondary--text font-weight-bold">
          <span > {{ currencyMask(item.valor) }} BRL </span>
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { currencyMask, formatDate } from "@/utils";

export default {
  data() {
    return {
      filters: [
        {
          label: "Entradas",
          value: "deposits",
        },
        {
          label: "Saídas",
          value: "withdrawals",
        },
      ],
      filter: "deposits",
      headers: [
        { text: "UCS", value: "contract" },
        { text: "Data", value: "date" },
        { text: "Status", value: "status" },
        { text: "Valor", value: "value" },
      ],
    };
  },

  props: {
    transactions: {
      type: Array,
      default: () => [],
    },
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    getData() {
      this.$emit("get:transactions", this.filter);
    },

    changeFilter(value) {
      this.filter = value;
      this.getData();
    },

    handleStatus(value) {
      switch (String(value)) {
        case "0":
          return "Aguardando";
        case "1":
          return "Aprovado";
        case "-1":
          return "Recusado";
        default: {
          return "";
        }
      }
    },

    currencyMask,

    formatDate,
  },
};
</script>

<style lang="scss" scoped>
::v-deep
  .custom-table.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td {
  border-bottom: none !important;
}

::v-deep .custom-table.v-data-table > .v-data-table__wrapper > table > thead {
  background-color: #fafafa;
}

::v-deep
  .custom-table.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  border-bottom: none !important;
  height: 32px !important;
}

.chip {
  background-color: #29aa4d20 !important;
}
</style>
