<template>
  <loader-view v-if="loading" />

  <v-container v-else class="py-12">
    <v-row>
      <v-col v-for="(item, i) in analytics" :key="i">
        <AnalyticCard :analytic="item" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <ProfitChart :values="lastIncomes" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <Transactions
          :transactions="_transactions"
          @get:transactions="handleTransactions($event)"
        />
      </v-col>

      <v-col v-if="properties.length > 0" cols="12" md="5" lg="4" xl="3">
        <PropertieCard :properties="properties" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { request } from "@/services";
import { currencyMask, formatAmount } from "@/utils";
import Transactions from "@/components/transactions/Transactions.vue";
import PropertieCard from "@/components/transactions/PropertieCard.vue";
import ProfitChart from "@/components/transactions/ProfitChart.vue";
import AnalyticCard from "@/components/transactions/AnalyticCard.vue";
const moment = require("moment");

export default {
  data() {
    return {
      loading: true,
      transactionType: "deposits",
      lastIncomes: [],
      properties: [],
      deposits: [],
      withdrawals: [],
      analytics: [
        {
          title: "Total depositado",
          value: "0",
        },
        {
          title: "Total sacado",
          value: "0",
        },
        {
          title: "Lucro total",
          value: "0",
        },
      ],
    };
  },

  components: {
    Transactions,
    PropertieCard,
    ProfitChart,
    AnalyticCard,
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    ...mapState(["user"]),

    _transactions() {
      if (this.transactionType === "deposits") return this.deposits;
      if (this.transactionType === "withdrawals") return this.withdrawals;
    },
  },

  methods: {
    async getData() {
      await this.getTransactions();
      await this.getDeposits();
      await this.getWithdrawals();
      await this.getDashboard();

      this.loading = false;
    },

    async getTransactions() {
      try {
        const payload = {
          method: "getTransactions",
        };

        await request(payload).then((res) => {
          this.analytics[0].value = currencyMask(res.totalDepositado) + " BRL";
          this.analytics[1].value = currencyMask(res.totalSaque) + " BRL";
          this.analytics[2].value = formatAmount(res.totalLucro) + " BRL";
        });
      } catch (err) {
        console.log(err);
      }
    },

    async getDeposits() {
      try {
        const payload = {
          method: "getDepositos",
        };

        await request(payload).then((res) => {
          this.deposits = res.depositos;
        });
      } catch (err) {
        console.log(err);
      }
    },

    async getWithdrawals() {
      try {
        const payload = {
          method: "getSaques",
        };

        await request(payload).then((res) => {
          this.withdrawals = res.saques;
        });
      } catch (err) {
        console.log(err);
      }
    },

    async getDashboard() {
      try {
        const payload = {
          method: "getDashboard",
        };

        await request(payload).then((res) => {
          this.lastIncomes = res.ultimosRendimentos.map((el) => ({
            date: el.date,
            value: el.valor,
            percent: el["valor%"],
          }));

          this.properties = res.contratos.map((el) => ({
            id: el.id,
            image: el.iconeContrato,
            name: el.nomeContrato,
            totalIncome: Number(el.saldoAtual) - Number(el.valorDeposito),
            balance: Number(el.saldoAtual),
            depositValue: Number(el.valorDeposito),
          }));
        });
      } catch (err) {
        console.log(err);
      }
    },

    handleTransactions(event) {
      this.transactionType = event;
    },

    handleDays(value) {
      const now = moment();
      return now.diff(value, "days");
    },
  },
};
</script>

<style></style>
