<template>
  <v-card v-if="properties.length > 0" class="pa-6 rounded-lg" outlined>
    <div class="d-flex align-center">
      <v-avatar class="mr-4">
        <v-img :src="_image" />
      </v-avatar>

      <div class="d-flex flex-column">
        <div class="secondary--text text-body-1">
          <span >
            {{ properties[index].name }}
          </span>
        </div>
        <div class="subtext--text text-body-2">
          <span > UCS </span>
        </div>
      </div>

      <v-spacer />

      <v-btn :disabled="properties.length === 1" icon @click="nextProperty()">
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>

    <v-divider class="my-4" />

    <div class="mb-2 subtext--text">
      <span > Rendimento </span>
    </div>

    <div class="d-flex align-center mb-6">
      <v-icon class="mr-2" color="green">mdi-check</v-icon>

      <div class="subtext--text mr-2">
        <span > Rendimento total </span>
      </div>

      <v-spacer />

      <div class="seconadry--text text-body-1">
        <span >
          {{ currencyMask(properties[index].totalIncome) }} BRL
        </span>
      </div>
    </div>

    <div class="d-flex align-center mb-6">
      <v-icon class="mr-2" color="green">mdi-home</v-icon>

      <div class="subtext--text mr-2">
        <span > Valor depositado </span>
      </div>

      <v-spacer />

      <div class="seconadry--text text-body-1">
        <span >
          {{ currencyMask(properties[index].depositValue) }} BRL
        </span>
      </div>
    </div>

    <v-btn
      class="rounded-lg overtext--text"
      color="primary2"
      block
      @click="openContract()"
    >
      Ver propriedade
    </v-btn>
  </v-card>
</template>

<script>
import { currencyMask } from "@/utils";

export default {
  data() {
    return {
      index: 0,
    };
  },

  props: {
    properties: {
      type: Array,
      default: [],
    },
  },

  computed: {
    _image() {
      return this.$store.getters.apiURL + this.properties[this.index].image;
    },
  },

  methods: {
    nextProperty() {
      if (this.index !== this.properties.length + 1) this.index += 1;
      else this.index = 0;
    },

    openContract() {
      this.$router.push({
        path: "/my-properties/" + this.properties[this.index].id,
      });
    },

    currencyMask,
  },
};
</script>

<style lang="scss" scoped></style>
