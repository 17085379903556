<template>
  <v-card class="pa-6 rounded-lg" outlined>
    <div class="mb-2 text-h6 secondary--text">Total de lucro</div>

    <div class="d-flex align-center">
      <div class="text-h5 secondary--text font-weight-bold mr-4">
        <span >{{ _totalProfit }} BRL</span>
      </div>

      <v-chip class="primary2 white--text" dark label>
        <span >+{{ _totalPercent }}%</span>
      </v-chip>
    </div>

    <apexchart
      width="100%"
      height="300"
      type="bar"
      :options="_options"
      :series="_series"
    />
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { currencyMask, formatAmount } from "@/utils";
const moment = require("moment");
moment.locale("pt-br");

export default {
  components: {
    apexchart: VueApexCharts,
  },

  props: {
    values: {
      type: Array,
      default: [],
    },
  },

  computed: {
    _options() {
      return {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          position: "top",
        },
        colors: ["#48AB6F"],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 5,
            columnWidth: 24,
          },
        },
        xaxis: {
          categories: this.values.map((el) => this.formatDate(el.date)),
        },
        fill: {
          opacity: 1,
          colors: ["#48AB6F"],
        },
      };
    },

    _series() {
      return [
        {
          name: "Lucro obtido",
          data: this.values.map((el) => el.value),
        },
      ];
    },

    _totalProfit() {
      let value = 0;

      this.values.forEach((el) => {
        value += Number(el.value);
      });

      return currencyMask(value);
    },

    _totalPercent() {
      let value = 0;

      this.values.forEach((el) => {
        value += Number(el.percent);
      });

      return formatAmount(value);
    },
  },

  methods: {
    formatDate(value) {
      return moment(value).format("ll");
    },
  },
};
</script>

<style lang="scss" scoped></style>
